import React, {useEffect, useState} from 'react';
import userService from "../../../../services/user-services";
import {useAuth} from "../../../../hooks/useAuth";

const AdminList = ({setActiveView}) => {

    const {logout} = useAuth();
    const [admins, setAdmins] = useState([]);
    const [deleteAdmin, setDeleteAdmin] = useState({});
    const [deleteAdminModal, setDeleteAdminModal] = useState(false);
    const [message, setMessage] = useState('')
    useEffect(() => {
        userService.getAdmins().then(res => {
            setAdmins(res.data.admins)
            res.data.status === 403 && logout()
        }, (error) => {
            error.response.status === 403 && logout()
        })
    }, [])

    const deleteA = id => {
        userService.deleteAdmin(id).then(async res => {
                await userService.getAdmins().then(res => {
                    setAdmins(res.data.admins)
                }, (error) => {
                    error.response.status === '403' && logout()
                })

                setDeleteAdminModal(false);
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setMessage(resMessage);
                error.response.statusText === 'Unauthorized' && logout()
            }
        )
        ;
    }

    const getAdmins = () => {
        return admins.map(admin => <div className='grid grid-cols-3 py-2 even:bg-gray-300/60 px-4 odd:bg-white/60'
                                        key={admin + Math.random()}>
                <div className="font-base">{admin.email}</div>
                <div className="font-base">{admin.role}</div>
                <div className={"flex justify-end items-center gap-2.5"}>
                    <a href={"/uj-jelszo/"}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512"
                             className={'w-4 h-4 cursor-pointer hover:scale-110 transition-all'}>
                            <path
                                d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                            <title>Új jelszó</title>
                        </svg>
                    </a>
                    {admin.role !== 'Super Administrator' && <div className="text-right flex">
                        <button onClick={() => {
                            setDeleteAdmin(admin);
                            setDeleteAdminModal(true)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 448 512" className='h-5 w-5 fill-red-700 hover:scale-110 transition-all'>
                                <path
                                    d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                                <title>Törlés</title>
                            </svg>
                        </button>
                    </div>}
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="heading">
                <h1 className='text-4xl font-bold text-brandSecondary font-header uppercase'>
                    Felhasználók
                </h1>
                <button className="btn-primary flex items-center gap-2 group" onClick={() => setActiveView('add')}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                         className="h-5 w-5 fill-current text-black group-hover:text-buttonHover">
                        <path
                            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                    </svg>
                    Új admin
                </button>
            </div>
            <div
                className=" grid grid-cols-3 justify-between border-b-[3px] border-brandPrimary pb-4 font-base font-bold">
                <div className=""></div>
                <div className="">Jogosultsági szint</div>
                <div className="text-right">Műveletek</div>
            </div>
            <div className="w-full py-5">
                {getAdmins()}
            </div>

            {deleteAdminModal &&
                <div
                    className="font-base absolute top-0 left-0 bottom-0 right-0 bg-gray-900/50 flex justify-center items-center">
                    <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8 text-center">
                        <h1 className="font-header font-black text-2xl text-brandSecondary uppercase">Admin törlése</h1>
                        <p className={'pb-5 border-b-[3px] border-brandPrimary mb-5 text-xs'}></p>
                        <p>Biztosan törli a következő admint:</p>
                        <p className="pb-5">
                        <span className="font-bold">
 {deleteAdmin.email}
                        </span>
                            ?
                        </p>
                        <div className="flex px-10 w-full justify-between">
                            <button className='btn-secondary' onClick={() => setDeleteAdminModal(false)}>Mégse
                            </button>
                            <button className='btn-primary' onClick={() => deleteA(deleteAdmin.id)}>Törlés</button>
                        </div>
                    </div>
                </div>
            }


        </>
    );
};

export default AdminList;
