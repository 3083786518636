import React, {useRef, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import userService from "../../../services/user-services";

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback absolute -top-5 text-red-500 text-xs">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const AddCategory = () => {
    const form = useRef();
    const checkBtn = useRef();
    const [category, setCategory] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);

    const onChangeCategory = e => {
        const value = e.target.value;
        setCategory(value)
    }

    const handleSubmit = e => {
        e.preventDefault();
        setMessage("");
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            userService.addCategory(category).then((res) => {
                setSuccess(true)
            }, (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setMessage(resMessage);
            });
        }
    }
    return (
        <Layout>
            <div className="flex flex-col container mx-auto">
                <div className="heading border-b-[3px] border-brandPrimary">
                    <h1 className='font-header text-4xl font-bold text-brandSecondary uppercase'>
                        Kategória hozzáadása
                    </h1>
                </div>
                {!success ? <Form onSubmit={handleSubmit} className='flex my-5 flex-col' ref={form}>

                    <div className="border-b-[3px] border-brandPrimary">
                        <label htmlFor="cat_name" className='font-base text-xs font-bold mb-1 uppercase'>Kategória
                            neve:</label>
                        <Input type="text" id="cat_name" required={true} value={category} onChange={onChangeCategory}
                               className='border-2 text-input w-1/4 mb-5' validations={[required]}/>
                    </div>

                    <div className="pb-5 text-red-500 font-bold">
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex gap-32 justify-start">
                        <a href="/kategoriak" className="btn-secondary w-fit self--start">
                            Mégsem
                        </a>
                        <button className="btn-primary w-fit self-start flex items-center gap-2 group">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                 className="h-5 w-5 fill-current text-black group-hover:text-buttonHover">
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                            </svg>
                            Új kategória hozzáadása
                        </button>


                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                    </div>

                </Form> : <>
                    <p className='font-bold my-5'>Új kategória hozzáadása sikeres!</p>
                    <a className="btn-primary w-fit self--start" href="/kategoriak">
                        Visszalépés a listaoldalra
                    </a>
                </>}
            </div>
        </Layout>
    )
        ;
};

export default AddCategory;
