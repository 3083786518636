import axios from "axios";

const API_URL = "https://www.googleapis.com/youtube/v3/";
const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

const getVideoDataByID = id => {
    return axios.get(API_URL + "videos?id=" + id + "&key=" + API_KEY + "&part=snippet,contentDetails", {})
}

const videoServicesYoutube = {getVideoDataByID}

export default videoServicesYoutube
