import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {useAuth} from "../../../hooks/useAuth";
import userService from "../../../services/user-services";

const Categories = () => {
    const {logout} = useAuth();
    const [categories, setCategories] = useState([]);
    const [deleteCategory, setDeleteCategory] = useState({});
    const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
    const [message, setMessage] = useState('')
    useEffect(() => {
        userService.getCategories().then(res => {
            setCategories(res.data.categories)
        }, (error) => {
            error.response.status === '403' && logout()
        })
    }, [])

    const deleteC = id => {
        userService.deleteCategory(id).then(async res => {
                await userService.getCategories().then(res => {
                    setCategories(res.data.categories)
                }, (error) => {
                    error.response.status === '403' && logout()
                })

                setDeleteCategoryModal(false);
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setMessage(resMessage);
                error.response.statusText === 'Unauthorized' && logout()
            }
        )
        ;
    }

    const getCategories = () => {
        return categories.map(category => <div
                className='flex justify-between py-2 even:bg-gray-300/60 px-4 odd:bg-[#ffffff40]'
                key={category + Math.random()}>
                <div className="font-base">{category.name}</div>
                <div className="flex gap-2 items-center">
                    <a href={'/kategoriak/szerkesztes/' + category.id}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512" className='h-5 w-5 hover:scale-110 transition-all'>
                            <path
                                d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                            <title>Szerkesztés</title>
                        </svg>
                    </a>
                    <button onClick={() => {
                        setDeleteCategory(category);
                        setDeleteCategoryModal(true)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 448 512" className='h-5 w-5 fill-red-700 hover:scale-110 transition-all'>
                            <path
                                d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                            <title>Törlés</title>
                        </svg>
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <Layout>
                <div className="flex flex-col container mx-auto">
                    <div className="heading">
                        <h1 className='font-header text-4xl font-bold text-brandSecondary uppercase'>
                            Kategóriák
                        </h1>
                        <a href="/kategoriak/uj-hozzaadasa" className="btn-primary flex items-center gap-2 group">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                 className="h-5 w-5 fill-current text-black group-hover:text-buttonHover">
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                            </svg>
                            Új kategória
                        </a>
                    </div>
                    <div className="flex justify-between border-b-[3px] border-brandPrimary pb-4 font-bold font-base">
                        <div className="">Kategóriák</div>
                        <div className="text-right">Műveletek</div>
                    </div>
                    <div className="w-full py-5">
                        {getCategories()}
                    </div>

                    {deleteCategoryModal &&
                        <div
                            className="font-base absolute top-0 left-0 bottom-0 right-0 bg-gray-900/50 flex justify-center items-center">
                            <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8 text-center">
                                <h1 className="font-header font-black text-2xl text-brandSecondary uppercase">Kategória
                                    törlése</h1>
                                <p className={'pb-5 border-b-[3px] border-brandPrimary mb-5 text-xs'}></p>
                                <p>Biztosan törli a következő kategóriát:</p>
                                <p className="pb-5">
                        <span className="font-bold">
 {deleteCategory.name}
                        </span>
                                    ?
                                </p>
                                <div className="flex px-10 w-full justify-between">
                                    <button className='btn-secondary'
                                            onClick={() => setDeleteCategoryModal(false)}>Mégsem
                                    </button>
                                    <button className='btn-primary' onClick={() => deleteC(deleteCategory.id)}>Törlés
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </Layout>
        </>
    );
};

export default Categories;
