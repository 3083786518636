import React, {useEffect, useRef, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import VideoEditDetails from "../VideoEditDetails/VideoEditDetails";
import {useParams} from "react-router-dom";
import userService from "../../../services/user-services";
import Loading from "../../../components/Loading/Loading";

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback absolute -top-5 text-red-500 text-xs">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const VideoEdit = () => {
    const videoTitle = useParams().id;
    const [isLoading, setIsLoading] = useState(true);
    const [videoData, setVideoData] = useState({});

    useEffect(() => {
        setIsLoading(true)
        userService.getVideoDataBySlug(videoTitle).then((res) => {
            setVideoData(res.data);
        })
    }, []);

    useEffect(() => {
        videoData.video !== undefined && setIsLoading(false)
    }, [videoData])
    return (
        <Layout padded={true}>
            <div className="flex flex-col container mx-auto">
                <div className="heading border-b-[3px] border-brandPrimary">
                    <h1 className='text-4xl font-bold text-brandSecondary uppercase'>
                        Videó szerkesztése
                    </h1>
                </div>
                <div className='container mx-auto'>
                    {isLoading && videoData ? <Loading/> : <VideoEditDetails videoData={videoData}/>}

                </div>
            </div>
        </Layout>
    );
};

export default VideoEdit;
