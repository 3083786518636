import React, {useRef, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import videoServicesVimeo from "../../../services/video-services-vimeo";
import VideoDetails from "../VideoDetails/VideoDetails";
import videoServicesYoutube from "../../../services/video-services-youtube";

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback absolute -top-5 text-red-500 text-xs">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const AddVideo = () => {
    const form = useRef();
    const checkBtn = useRef();
    const [videoId, setVideoId] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [videoData, setVideoData] = useState({});

    const onChangeVideoId = e => {
        const value = e.target.value;
        setVideoId(value)
    }


    const handleSubmit = e => {
        e.preventDefault();
        setMessage("");
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {

            if(videoId.search("vimeo.com") >= 0) { // get from URL: Vimeo video

                const videoIdentifier = videoId.substring(videoId.lastIndexOf('/')+1); // just the ID
                videoServicesVimeo.getVideoDataByID(videoIdentifier).then((res) => {
                    setVideoData(res.data);
                    console.log(res)
                    setSuccess(true);
                }, (error) => {
                    const resMessage = error.response.data.developer_message || error.response.data.error;
                    setMessage(resMessage);
                });

            } else if(videoId.search("youtube.com") >= 0) { // Youtube video

                const videoIdentifier = videoId.substring(videoId.lastIndexOf('=')+1); // just the ID
                videoServicesYoutube.getVideoDataByID(videoIdentifier).then((res) => {
                    console.log(res);
                    setVideoData(res.data);
                    setSuccess(true);
                }, (error) => {
                    const resMessage = error.response.data.developer_message || error.response.data.error;
                    setMessage(resMessage);
                });

            } else {
                setMessage('Nem felismerhető URL');
            }
        }
    }
    return (
        <Layout padded={true}>
            <div className="flex flex-col container mx-auto">
                <div className="heading border-b-[3px] border-brandPrimary">
                    <h1 className='font-header text-4xl font-bold text-brandSecondary uppercase'>
                        Videó hozzáadása
                    </h1>
                </div>
                {!success ? <Form onSubmit={handleSubmit} className='flex my-5 flex-col' ref={form}>

                    <div className={"flex gap-6 border-b-[3px] border-brandPrimary"}>
                        <div className="w-1/4">
                            <label
                                className={"font-base text-base font-black py-1.5 px-3 min-h-10 inline-block "}> Vimeo /
                                YouTube Link</label>
                            <Input type="text" id="cat_name" required={true} value={videoId} onChange={onChangeVideoId}
                                   className='border-2 text-input w-full mb-5' validations={[required]}/>
                        </div>
                        <div className={"w-3/4 pt-6 text-[12px]"}>
                            <b>Elfogadott formátumok:</b><br />
                            <b>Youtube:</b> https://www.youtube.com/watch?v=AHP1T8fTxpQ<br />
                            <b>Vimeo:</b> https://vimeo.com/manage/videos/756758928
                        </div>
                    </div>

                    <div className="pb-5 text-red-500 font-bold">
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex gap-10">
                        <a href="/" className="btn-secondary w-fit self--start">
                            Mégsem
                        </a>
                        <button className="btn-primary w-fit self-start flex items-center gap-2 group">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                 className="h-5 w-5 fill-current text-black group-hover:text-buttonHover">
                                <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                            </svg>
                            Hozzáadás
                        </button>

                        <CheckButton style={{display: "none"}} ref={checkBtn}/>
                    </div>

                </Form> : <div className='container mx-auto'>
                    <VideoDetails videoData={videoData} videoId={videoId}/>
                </div>}
            </div>
        </Layout>
    );
};

export default AddVideo;
