import './App.css';
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import ForgotPW from "./pages/ForgotPW/ForgotPW";
import PWReset from "./pages/PWReset/PWReset";
import ProtectedRoute from "./components/Routing/ProtectedRoute";
import NewPassword from "./pages/NewPassword/NewPassword";
import Users from "./pages/Users/Users";
import Categories from "./pages/Category/Categories/Categories";
import CategoryEdit from "./pages/Category/CategoryEdit/CategoryEdit";
import AddCategory from "./pages/Category/AddCategory/AddCategory";
import AddVideo from "./pages/Home/AddVideo/AddVideo";
import VideoEdit from "./pages/Home/VideoEdit/VideoEdit";
import NotFound from "./pages/NotFound/NotFound";

const App = () => {
    return (
        <Routes>
            <Route path="/bejelentkezes" element={<Login/>}/>
            <Route path="/elfelejtett-jelszo" element={<ForgotPW/>}/>
            <Route path="/jelszo-visszaallitas" element={<PWReset/>}/>
            <Route path="/" element={<ProtectedRoute/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/uj-jelszo" element={<NewPassword/>}/>
                <Route path="/felhasznalok" element={<Users/>}/>
                <Route path="/kategoriak" element={<Categories/>}/>
                <Route path="/kategoriak/uj-hozzaadasa" element={<AddCategory/>}/>
                <Route path="/kategoriak/szerkesztes/:id" element={<CategoryEdit/>}/>
                <Route path="/uj-video-hozzaadasa" element={<AddVideo/>}/>
                <Route path="/video-szerkesztese/:id" element={<VideoEdit/>}/>
            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}

export default App;
