import {createContext, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "./useLocalStorage";

const UseAuth = createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useLocalStorage("user", null);
    const [role, setRole] = useLocalStorage("role", null);
    const [isConfirmed, setIsConfirmed] = useLocalStorage("confirmed", null);
    const navigate = useNavigate();

    const login = async (data) => {
        setUser({data});

        navigate("/", {replace: true});
    };
    const storeRole = async (data) => {
        setRole({data});
    }
    const storeConfirmed = async (data) => {
        setIsConfirmed({data});
    }
    const logout = () => {
        setUser(null);
        setRole(null);
        setIsConfirmed(null)
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        localStorage.removeItem("confirmed")
        navigate("/bejelentkezes", {replace: true});
    };

    const value = useMemo(
        () => ({
            user,
            role,
            login,
            logout,
            storeRole,
            isConfirmed,
            storeConfirmed
        }),
        [user]
    );

    return <UseAuth.Provider value={value}>{children}</UseAuth.Provider>;
};

export const useAuth = () => {
    return useContext(UseAuth);
};
