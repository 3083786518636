import {React, useRef, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import Layout from "../../components/Layout/Layout";
import classes from './PWReset.module.scss';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import key from '../../assets/key.svg';
import {NavLink, useParams, useSearchParams} from "react-router-dom";
import AuthService from "../../services/auth-services";

let passwordValue = '';
const regPW = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$");
const vpassword = (value) => {
    passwordValue = value;
    if (value.length < 8 || regPW.test(value)) {
        return (
            <div
                className="text-xs absolute text-left left-1/2 -translate-x-1/2 -bottom-5 text-red-500 w-full text-center"
                role="alert">
                Nem megfelelő jelszó formátum!
            </div>
        );
    }
};
const vpasswordConfirm = (value) => {
    if (value !== passwordValue) {
        return (
            <div
                className="text-xs absolute text-left left-1/2 -translate-x-1/2 -bottom-5 text-red-500 w-full text-center"
                role="alert">
                A jelszavak nem egyeznek meg!
            </div>
        );
    }
}
const required = (value) => {
    if (!value) {
        return (
            <div
                className="invalid-feedback absolute -bottom-5 left-1/2 -translate-x-1/2 text-red-500 text-xs w-full text-center">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const PWReset = () => {
    const form = useRef();
    const checkBtn = useRef();
    const {user} = useAuth();
    const {logout} = useAuth();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState('');
    const [isNewPasswordSet, setIsNewPasswordSet] = useState(false)
    const token = searchParams.get("token");


    const submit = (event) => {
        event.preventDefault();
        if (!user) {
            AuthService.newPassword(password, passwordConfirmation, token).then(() => {
                setIsNewPasswordSet(true)
            }, (error) => {
                const resMessage = error.response.data.error.password[0];
                setMessage(resMessage);
            });
        } else {
            AuthService.setNewPassword(password, passwordConfirmation).then(() => {
                logout()
            }, (error) => {
                const resMessage = error.response.data.error.password[0];
                setMessage(resMessage)
            });
        }


    }

    const onChangePassword = e => {
        const value = e.target.value;
        setPassword(value);
    }
    const onChangePasswordConfirmation = e => {
        const value = e.target.value;
        setPasswordConfirmation(value);
    }

    return (<Layout>
        <div className={"flex w-full items-center justify-center " + classes["background"]}>
            <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8">
                <div className="text-center">
                    <h1 className="font-header font-black text-2xl text-brandSecondary mb-2">JACSOMEDIA VIDEO
                        PORTAL</h1>
                    <div className={'mb-5 md:mb-12 text-xs'}><p
                        className='mb-5 text-brandSecondary'>
                        Új jelszó készítése
                    </p> {!isNewPasswordSet && <p>
                        A jelszónak legalább 8 karakter hosszúságúnak kell lennie, tartalmaznia kell legalább egy
                        kis betűt, egy nagy betűt és egy speciális karaktert.
                    </p>}</div>
                    <div className={'flex flex-col gap-8 mb-5 md:mb-8 items-center'}>
                        {!isNewPasswordSet ?
                            <Form onSubmit={submit} className="flex flex-col gap-8 items-center" ref={form}>
                                <div className="relative">
                                    <div className="text-left mb-1 text-sm font-black">Új jelszó:</div>
                                    <div className="flex">

                                        <Input type="password" id="password" onChange={onChangePassword}
                                               value={password}
                                               className="login-input w-[350px]" required
                                               placeholder='Új jelszó' validations={[required, vpassword]}/>
                                    </div>

                                </div>
                                <div className="relative">
                                    <div className="text-left mb-1 text-sm font-black">Új jelszó megerősítése:</div>
                                    <div className="flex">

                                        <Input type="password" id="passwordConfirmation"
                                               onChange={onChangePasswordConfirmation} value={passwordConfirmation}
                                               className="login-input w-[350px]" required
                                               placeholder='Új jelszó megerősítése'
                                               validations={[required, vpasswordConfirm]}/>
                                    </div>

                                </div>

                                {message && (
                                    <div className="form-group">
                                        <div className="alert alert-danger text-red-500" role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}

                                <button type="send" className='btn-primary w-fit mt-2'>Új jelszó beállítása</button>
                                <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            </Form> : <><p className="text-brandPrimary font-black text-center">
                                Sikeresen beállította az új jelszavát!
                            </p>
                                <NavLink to="/bejelentkezes" className='menu-link'>Bejelentkezés</NavLink>
                            </>}
                    </div>
                </div>

            </div>
        </div>
    </Layout>);
};

export default PWReset;
