import axios from "axios";

const API_URL = "https://api.vimeo.com/";


const getVideoDataByID = id => {
    return axios.get(API_URL + "me/videos/" + id, {
        headers: {
            Authorization: 'Bearer ' + process.env.REACT_APP_VIMEO_API_KEY,
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            'Content-Type': 'application/json'
        }
    })
}

const videoServicesVimeo = {getVideoDataByID}

export default videoServicesVimeo
